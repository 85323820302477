import lang from '@/lang'

export const COOKIE_NAME_AUTHENTICATED = 'app_authenticated'

export const LOCAL_STORAGE_NAME_REMEMBER_ME = 'app_remember_me'

export const DATE_RANGE_OPTION = {
  shortcuts: [
    {
      text: lang.i18n.global.t('common.last_week'),
      onClick(picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: lang.i18n.global.t('common.last_month'),
      onClick(picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: lang.i18n.global.t('common.last_three_week'),
      onClick(picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
        picker.$emit('pick', [start, end])
      }
    }
  ]
}

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
export const DEFAULT_DATE_TIME_FORMAT = 'yyyy-MM-dd hh:mm:ss'

export const GLOBAL_EVENTS = {
  NOTIFICATION_UPDATED: 'NOTIFICATION_UPDATED'
}
