import * as consts from '@/common/consts'
import { getConfig } from '@/common/config'
import { useCookies } from 'vue3-cookies'

let vueCookies = useCookies()

export function config() {
  let cookiePath = getConfig().oauth2.cookiePath || '/'
  vueCookies.cookies.config('session', cookiePath)
  console.log('vue cookies configured.', cookiePath)
}

export function set(isRememberMe) {
  vueCookies.cookies.set(consts.COOKIE_NAME_AUTHENTICATED, '1')

  if (isRememberMe) {
    localStorage.setItem(consts.LOCAL_STORAGE_NAME_REMEMBER_ME, '1')
  }
}

export function clear() {
  vueCookies.cookies.remove(consts.COOKIE_NAME_AUTHENTICATED)

  localStorage.removeItem(consts.LOCAL_STORAGE_NAME_REMEMBER_ME)
}

export function isValid() {
  let flag = vueCookies.cookies.get(consts.COOKIE_NAME_AUTHENTICATED)

  console.log('consts.COOKIE_NAME_AUTHENTICATED', flag)

  if (flag === '1') {
    return true
  }

  let rememberMeFlag = localStorage.getItem(consts.LOCAL_STORAGE_NAME_REMEMBER_ME)
  if (rememberMeFlag === '1') {
    vueCookies.cookies.set(consts.COOKIE_NAME_AUTHENTICATED, '1')
    return true
  }

  return false
}
