import * as util from '@/common/util'

const globalMixin = {
  methods: {
    showSuccess(msg) {
      this.$message.success(msg)
    },
    showWarning(msg) {
      this.$message.warning(msg)
    },
    showError(error) {
      this.$message.error(this.$t('error.service_call_fail') + util.getErrorMessage(error))
    },
    formatDate(row, column, cellValue) {
      return util.formatDate.format(new Date(cellValue), 'yyyy-MM-dd')
    },
    formatDateTime(row, column, cellValue) {
      if (cellValue == null) return ''
      return util.formatDate.format(new Date(cellValue), 'yyyy-MM-dd hh:mm')
    },
    formatDateTimeSeconds(row, column, cellValue) {
      if (cellValue == null) return ''
      return util.formatDate.format(new Date(cellValue), 'yyyy-MM-dd hh:mm:ss')
    },
    formatBoolean(row, column, cellValue) {
      return this.$i18n.t(util.formatBoolean(cellValue))
    }
  }
}

export default globalMixin
