let routes = [
  {
    path: '/signin',
    component: () => import('@/views/SigninPage.vue'),
    name: '',
    hidden: true,
    meta: {
      title: 'signin.signin'
    }
  },
  {
    path: '/other',
    component: () => import('@/views/MainOtherPage.vue'),
    name: '',
    children: [
      {
        path: '/oidc-callback',
        component: () => import('@/views/OidcCallback.vue'),
        name: '',
        hidden: true,
        meta: {
          title: 'router.loading'
        }
      }
    ]
  },
  {
    path: '/other',
    component: () => import('@/views/MainOtherPage.vue'),
    name: '',
    children: [
      {
        path: '/oidc-silent-callback',
        component: () => import('@/views/OidcSilentCallback.vue'),
        name: '',
        hidden: true,
        meta: {
          title: 'router.loading'
        }
      }
    ]
  },
  {
    path: '/other',
    component: () => import('@/views/MainOtherPage.vue'),
    name: '',
    children: [
      {
        path: '/403',
        component: () => import('@/views/403.vue'),
        name: '',
        hidden: true,
        meta: {
          title: 'router.access_denied'
        }
      }
    ]
  },
  {
    path: '/other',
    component: () => import('@/views/MainOtherPage.vue'),
    name: '',
    children: [
      {
        path: '/no-permission',
        component: () => import('@/views/NoPermissionPage.vue'),
        name: 'no-permission',
        hidden: true,
        meta: {
          title: 'router.no_permission'
        }
      }
    ]
  },
  {
    path: '/other',
    component: () => import('@/views/MainOtherPage.vue'),
    name: '',
    children: [
      {
        path: '/404',
        component: () => import('@/views/404.vue'),
        name: '',
        hidden: true,
        meta: {
          title: 'router.not_found'
        }
      }
    ]
  },
  {
    path: '/other',
    component: () => import('@/views/MainOtherPage.vue'),
    name: '',
    children: [
      {
        path: '/error',
        component: () => import('@/views/ErrorPage.vue'),
        name: 'error',
        hidden: true,
        meta: {
          title: 'router.error'
        }
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/views/MainPage.vue'),
    name: '',
    leaf: true,
    meta: {
      title: 'router.root'
    },
    children: [
      {
        path: '/',
        component: () => import('@/views/home/HomePage.vue'),
        name: 'home',
        meta: {
          title: 'router.home'
        }
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/views/MainPage.vue'),
    name: '',
    leaf: true,
    meta: {
      title: 'router.root'
    },
    children: [
      {
        path: '/notification',
        component: () => import('@/views/notification/NotificationPage.vue'),
        name: 'notification',
        meta: {
          title: 'router.notification'
        }
      },
      {
        path: '/notification-detail/:uId',
        component: () => import('@/views/notification/NotificationDetailPage.vue'),
        name: 'notification-detail',
        hidden: true,
        props: (route) => ({ notification: route.params.notification }),
        meta: {
          title: 'router.notification'
        }
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/views/MainPage.vue'),
    name: 'system-main',
    meta: {
      title: 'router.system'
    },
    children: [
      {
        path: '/system-log',
        component: () => import('@/views/systemLog/SystemLogPage.vue'),
        name: 'system-log',
        meta: {
          protected: true,
          title: 'router.system_log_main'
        }
      },
      {
        path: '/system-config',
        component: () => import('@/views/systemConfig/SystemConfigPage.vue'),
        name: 'system-config',
        meta: {
          protected: true,
          title: 'router.system_config_main'
        }
      },
      {
        path: '/event',
        component: () => import('@/views/event/EventPage.vue'),
        name: 'event',
        meta: {
          protected: true,
          title: 'router.event_main'
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    hidden: true,
    redirect: { path: '/404' }
  }
]

export { routes }
