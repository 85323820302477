import { useOAuth2Store } from '@/stores/oauth2Store'

// private methods
let calculateExpirationTime = (tokenInfo) => {
  console.log('start to calculate token expiration in minutes', tokenInfo)

  if (!tokenInfo) {
    console.log('no token found, return.')
    return -1
  }

  let exp = tokenInfo.expireTime
  let expireTime = new Date(exp * 1000)

  console.log('expireTime', expireTime)

  var diff = expireTime - new Date()
  var mins = Math.floor(diff / 1000 / 60)

  console.log('expire in mins:', mins)

  return mins
}

// public methods
export let getTokenExpirationInMinutes = (tokenInfo) => {
  const oauth2Store = useOAuth2Store()
  var mins = calculateExpirationTime(tokenInfo)

  let rt = oauth2Store.getterRefreshToken

  if (mins < 5 && rt) {
    return oauth2Store
      .doRefreshToken()
      .then((ti) => {
        console.log('oauth2Helper:getTokenExpirationInMinutes, refreshed token.')

        return calculateExpirationTime(ti)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  return new Promise((resolve) => {
    resolve(mins)
    // setTimeout(resolve, 1, mins)zs
  })
}

export let getAccessToken = (tokenInfo) => {
  const oauth2Store = useOAuth2Store()
  var mins = calculateExpirationTime(tokenInfo)

  let rt = oauth2Store.getterRefreshToken

  if (mins < 5 && rt) {
    return oauth2Store
      .doRefreshToken()
      .then((ti) => {
        console.log('oauth2Helper:getAccessToken, refreshed token.')

        return ti.accessToken
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  return new Promise((resolve) => {
    resolve(tokenInfo.accessToken)
    // setTimeout(resolve, 1, tokenInfo.accessToken)
  })
}

export let hasAccess = (rolesDefinitions) => {
  const oauth2Store = useOAuth2Store()
  if (!rolesDefinitions) {
    // no access control
    return true
  }

  let tokenInfo = oauth2Store.getterTokenInfo

  if (!tokenInfo || !tokenInfo.token) {
    return false
  }

  var currentRoles = tokenInfo.token.roles

  for (const currentRole of currentRoles) {
    for (const rolesDefinition of rolesDefinitions) {
      if (currentRole === rolesDefinition) {
        return true
      }
    }
  }

  return false
}
