import enLocale from 'element-plus/dist/locale/en.mjs'

let en = {
  el: enLocale.el,
  common: {
    current_locale: 'English',
    current_user: 'Current User',
    chinese: '中文',
    english: 'English',
    search: 'Search',
    cancel: 'Cancel',
    submit: 'Submit',
    confirm: 'Confirm',
    add: 'Add',
    create: 'Create',
    edit: 'Edit',
    update: 'Update',
    delete: 'Delete',
    batch_delete: 'Batch Delete',
    remove: 'Remove',
    import: 'Import',
    export: 'Export',
    action: 'Action',
    general_confirm: 'Are you sure?',
    delete_confirm: 'Are you sure to delete this record?',
    batch_delete_confirm: 'Are you sure to batch delete these record(s)?',
    prompt: 'Prompt',
    no_record: 'No record is selected.',
    create_success: 'Record has been created successfully.',
    update_success: 'Record has been updated successfully.',
    delete_success: 'Record has been deleted successfully.',
    batch_delete_success: 'Record(s) have been deleted successfully.',
    u_id: 'UId',
    name: 'Name',
    display_name: 'Display Name',
    type: 'Type',
    enabled: 'Enabled',
    description: 'Description',
    create_by: 'Created By',
    create_time: 'Created Time',
    update_by: 'Updated By',
    update_time: 'Updated Time',
    mandatory: 'Mandatory',
    format_error: 'Wrong Format',
    import_prompt: 'Please drag the file here or click upload',
    import_tip: '.xlsx file only',
    please_select: 'Please Select',
    all: 'All',
    save: 'Save',
    yes: 'Yes',
    no: 'No',
    start_date: 'Start Date',
    end_date: 'End Date',
    to: 'To',
    last_three_week: 'Last 3 Weeks',
    last_month: 'Last Month',
    last_week: 'Last Week',
    detail: 'Detail',
    ok: 'OK',
    system_info: 'System Info',
    os_platform: 'OS',
    runtime_version: 'Runtime Version',
    memory_usage: 'Memory Usage',
    app_version: 'System Version',
    app_build_time: 'Built On',
    app_git_commit: 'Commit Info',
    select_date: 'Select Date',
    key: 'Key',
    value: 'Value',
    reset: 'Reset',
    common_filter: 'Common Filter',
    true: 'True',
    false: 'False',
    copied: 'Content Copied!',
    copy_failed: 'Content Copy Failed!',
    system_name: '',
    error: 'Error',
    signout_try_again: 'Sign Out and Retry',
    back: 'Back'
  },
  router: {
    root: 'Root',
    home: 'Home',
    notification: 'Notifications',
    system: 'System',
    system_log_main: 'System Log',
    system_config_main: 'System Config',
    loading: 'Loading...',
    error: 'Error',
    access_denied: 'Access denied',
    not_found: 'Page not found.',
    event_main: 'Events',
    event: 'Events',
    no_permission: 'No permission'
  },
  signin: {
    signin: 'Sign In',
    please_signin: 'Please Sign In',
    user_name: 'Username',
    password: 'Password',
    remember_password: 'Remember Me',
    user_name_missing: 'Please input user name.',
    password_missing: 'Please input password.',
    terms_and_conditions: 'Terms & Conditions',
    privacy_statement: 'Privacy Statement',
    signin_failed: 'Sign in failed',
    sign_in_failed_prompt: 'Sign in failed, reason: {reason}'
  },
  error: {
    error: 'Error',
    back_to_home: 'Back to Home',
    service_call_fail: 'Calling service failed, detail: ',
    no_permission_prompt:
      "Sorry, you don't have any permission in the system, please contact the administrator for help."
  },
  json_ql: {
    all_records: 'All Records View',
    create_view: 'Create View',
    update_view: 'Update View',
    clone_view: 'Clone View',
    delete_view: 'Delete View',
    filters: 'Filters',
    cancel: 'Cancel',
    save_filter: 'Save Filter',
    add_filter: 'Add Filter',
    mandatory: 'Mandatory',
    view_detail: 'View Detail',
    name: 'Name',
    match_all_filters: 'Match all the filters with AND logic',
    match_custom_logic: 'Match filters with customized logic',
    remove_all_filter_prompt: 'Remove All Filter Prompt',
    confirm: 'Confirm',
    remove_all: 'Remove All',
    filter_logic: 'Filter Logic',
    add_filter_logic: 'Add Filter Logic',
    remove_filter_logic: 'Remove Filter Logic',
    delete_confirm: 'Are you sure to delete?',
    delete_prompt: 'Confirmation',
    field: 'Field',
    operator: 'Operator',
    value: 'Value',
    eq: 'equals',
    neq: 'not equal to',
    exist: 'exists',
    prefix: 'start with',
    suffix: 'end with',
    like: 'contains',
    nlike: 'not contain',
    in: 'in',
    nin: 'not in',
    gt: 'greater than',
    lt: 'less than',
    gte: 'greater or equal',
    lte: 'less or equal',
    done: 'Done',
    select_date_time: 'Select date time'
  },
  home: {
    my_message: 'My Message',
    profile: 'Profile',
    settings: 'Settings',
    logout: 'Logout',
    logout_prompt: 'Are you sure to logout?',
    logout_caption: 'Confirmation',
    clear_cache: 'Clear Cache',
    cache_cleared_success: 'Cache was cleared successfully!',
    fullscreen: 'Fullscreen',
    exit_fullscreen: 'Exit Fullscreen',
    fullscreen_not_supported: 'Fullscreen is not supported by your browser.',
    theme: 'Theme',
    theme_default: 'Default',
    theme_dark: 'Dark'
  },
  system_log: {
    operation_time: 'Operation Time',
    operation_object: 'Operation Object',
    operation_object_id: 'Operation Object Id',
    operation: 'Operation',
    operator_ip: 'Operator Ip',
    operator_name: 'Operator Name',
    category: 'Category',
    source: 'Source',
    type: 'Type',
    id: 'Id',
    data: 'Data',
    remark: 'Remark'
  },
  system_config: {
    key: 'Key',
    value: 'Value'
  },
  event: {
    generate_events: 'Generate Events'
  },
  notification: {
    notification: 'Notifications',
    view_all: 'View all notifications',
    user_u_id: 'User UId',
    is_read: 'Is Read',
    is_delete: 'Is Delete',
    type: 'Type',
    status: 'Status',
    title: 'Title',
    message: 'Message',
    priority: 'Priority',
    expiration_time: 'Expiration Time',
    url: 'Url',
    icon: 'Icon',
    additional_data: 'Additional Data',
    mark_all_read: 'Mark all read',
    mark_unread: 'Mark as unread',
    mark_read: 'Mark as read'
  },
  message: 'hello',
  ...enLocale // Or use `Object.assign({ message: 'hello' }, enLocale)`
}

export default en
